const firebaseConfig = {
  apiKey: "AIzaSyCGsUtp5Rh1h7bHsTprDt3HbJnuNivJpls",
  authDomain: "kishalayabiddaniketan-4a78d.firebaseapp.com",
  projectId: "kishalayabiddaniketan-4a78d",
  storageBucket: "kishalayabiddaniketan-4a78d.appspot.com",
  messagingSenderId: "12038274670",
  appId: "1:12038274670:web:333028d6fd04d1d6a5707f",
  measurementId: "G-CHS30RMMKV",
};

export default firebaseConfig;
