import React from "react";
import SingleFileUpload from "./SingleFileUpload";

const ResultUpload = () => {
  return (
    <div>
      <SingleFileUpload type="Result" />
    </div>
  );
};

export default ResultUpload;
